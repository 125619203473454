/*validation for donations form */

export default class donation {
    constructor() {

        const donate = document.getElementById('donationNow');

        if (donate) {
            this.donationForm();
        }
    }

    donationForm() {
        console.log("donation begun");
        $("input[type=\'radio\'][name=\"formfields[donor_memory]\"]").on("change", function(){
            var selected = $("input[type=\'radio\'][name=\"formfields[donor_memory]\"]:checked").val();
            //console.log(selected+" chosen");
            if (selected == "In Memory") {
                //console.log("show In Memory plate");
                $("#memoryPlate").removeClass("d-none");
                $("#honorPlate").addClass("d-none");
            } else if(selected == "In Honor"){
                //console.log("show In Honor plate");
                $("#memoryPlate").addClass("d-none");
                $("#honorPlate").removeClass("d-none");
            } else {
                $("#honorPlate").addClass("d-none");
                $("#memoryPlate").addClass("d-none");
            }
        });

        $("input[type=\'radio\'][name=\"formfields[donor_notification]\"]").on("change", function(){
            var notifselect = $("input[type=\'radio\'][name=\"formfields[donor_notification]\"]:checked").val();
            //console.log(notifselect+" chosen");
            if (notifselect == "Yes") {
                $("#notificationDets").removeClass("d-none");
            } else {
                $("#notificationDets").addClass("d-none");
            }
        });
    }
}

