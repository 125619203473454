/*create slider for movies page*/

export default class ViridanPhotoTour {
    constructor() {

        const photoList = document.getElementById('viridanPhotos');

        if (photoList) {
            this.setSlick();
        }
    }

    setSlick() {
        console.log("slicks added now!");
        $('.slick').slick({
            arrows: true,
            autoplay: false,
            dots: false,
            infinite: true,
            lazyload: "progressive",
            prevArrow:"<button type='button' class='slcol-slick-prev'><svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24'><path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path></svg></button>",
            nextArrow:"<button type='button' class='slcol-slick-next'><svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24'><path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path></svg></button>",
            slidesToShow: 5,
            slidesToScroll: 5,
            touchMove: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        dots: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,

                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 2,
                        swipeToSlide: true,
                        touchMove: true,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 2,
                        swipeToSlide: true,
                        touchMove: true,
                    }
                },
                {
                    breakpoint: 360,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                        swipeToSlide: true,
                        touchMove: true,
                    }
                }
            ]
        });
    }
}

