/*validation for 3d print form */

export default class purchaseSuggestion {
    constructor() {

        const suggestion = document.getElementById('suggestPurchase');

        if (suggestion) {
            this.purchaseSug();
        }
    }

    purchaseSug() {
        console.log("suggest a purchase");
        $("#StaffResponse").addClass("d-none hide");
        $("#informedFriend").html("If you would like to be informed of the Library's purchase decision enter an email address.");
    }
}

