export default class borrowAnother {
    constructor() {
        const requestAnother = document.getElementById('balChoices');
        if (requestAnother) {
            this.findChoice();
        }
    }
    findChoice() {

        console.log("Borrow from another library added");
        //radio selection inital setup cause its not respecting the html stuff
        //magazines is checked to start with
        $('#magazineChoice').attr("checked", true);
        $('#microformChoice').removeAttr("checked", true);
        //only free is checked to start with
        $('#balFree').attr("checked", true);
        $('#balFree').removeAttr("checked", true);
        $('#balTen').removeAttr("checked", true);
        $('#balTwentyFive').removeAttr("checked", true);

        $('input[type=radio][name="formfields[bal_request_type]"]').on('change',function() {
            if ($(this).attr('id') === 'microformChoice') {
                console.log("microform");
                $('#microformChoice').attr("checked", true);
                $('#magazineChoice').removeAttr("required", true);
                //hide magazine
                $('#GroupDropdownMagazine').addClass("d-none");
                //remove attributes for magazine
                $('#balMagazineTitle').removeAttr("required", true);
                $('#balArticleTitle').removeAttr("required", true);
                //show Microform
                $('#GroupDropdownMicroform').removeClass("d-none");
                //make microform stuff required
                $('#balMicroformTitle').attr("required", true);
                $('#balPublicationDate').attr("required", true);
            } else if ($(this).attr('id') === 'magazineChoice') {
                console.log("magazine");
                $('#magazineChoice').attr("checked", true);
                $('#microformChoice').removeAttr("checked", true);
                //hide microform
                $('#GroupDropdownMicroform').addClass("d-none");
                //remove attributes for microform
                $('#balMicroformTitle').removeAttr("required", true);
                $('#balPublicationDate').removeAttr("required", true);
                //show magazine
                $('#GroupDropdownMagazine').removeClass("d-none");
                //make magazine stuff required
                $('#balMagazineTitle').attr("required", true);
                $('#balArticleTitle').attr("required", true);
            } else {
                //do nothing

            }
        });
        $('input[type=radio][name="formfields[bal_cost]"]').on('change',function(){
            if($(this).attr('id') === 'balFree') {
                //bal free selected
                console.log("free");
                $('#balFree').attr("checked", true);
                //remove attributes other costs balances
                $('#balFive').removeAttr("checked", true);
                $('#balTen').removeAttr("checked", true);
                $('#balTwentyFive').removeAttr("checked", true);
            } else if($(this).attr('id') === 'balFive') {
                //bal five selected
                console.log("five");
                $('#balFive').attr("checked", true);
                //remove attributes other costs balances
                $('#balFree').removeAttr("checked", true);
                $('#balTen').removeAttr("checked", true);
                $('#balTwentyFive').removeAttr("checked", true);
            } else if($(this).attr('id') === 'balTen') {
                //bal ten selected
                console.log("Ten");
                $('#balTen').attr("checked", true);
                //remove attributes other costs balances
                $('#balFree').removeAttr("checked", true);
                $('#balFive').removeAttr("checked", true);
                $('#balTwentyFive').removeAttr("checked", true);
            } else if($(this).attr('id') === 'balTwentyFive') {
                //bal twenty five selected
                console.log("Twenty five");
                $('#balTwentyFive').attr("checked", true);
                //remove attributes other costs balances
                $('#balFree').removeAttr("checked", true);
                $('#balFive').removeAttr("checked", true);
                $('#balTen').removeAttr("checked", true);
            } else {
                //do nothing

            }

        });
    }
}

