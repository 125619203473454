/*open modal if has appears*/

export default class modalOpen {
    constructor() {

        const hash = document.getElementById('modalOpen');

        if (hash) {
            this.openModal();
        }
    }

    openModal() {
        console.log("modal will be opened");
        var hash = window.location.hash;
        var hashlesshash = hash.replace("#", "");

        if(hash){
            console.log(hash);
            $(hash).modal('toggle');

        } else {
            console.log('no modal');
        }

    } //end of openHashes
}

