/*validation for 3d print form */

export default class printThreed {
    constructor() {

        const printThree = document.getElementById('three-d-print');

        if (printThree) {
            this.threeDprint();
        }
    }

    threeDprint() {
        console.log("3d print");
        var today = new Date();
        $('#requestDate').addClass("d-none hide");
        $('label[for="requestDate"]').addClass("d-none hide");
        $('#requestDate').attr("value", "Form submitted: " + today );

        $("#thingiverse_details").on("input", function(){
            //console.log("change is happening");
            $("#limit_count4").html("("+$(this).val().length+"/3000)");
            //console.log($(this).val().length)
            if($(this).val().length > 3000){
                $(this).val($(this).val().substring(0, 3000));
                $("#limit_count4").html("(3000/3000)");
            }
        });

        $("#file_url").on("input", function(){
            //console.log("change is happening");
            $("#limit_count5").html("("+$(this).val().length+"/200)");
            //console.log($(this).val().length)
            if($(this).val().length > 200){
                $(this).val($(this).val().substring(0, 200));
                $("#limit_count5").html("(200/200)");
            }

            var pattern = new RegExp("(https:\/\/)([a-zA-Z0-9]{2,10})\.([a-zA-Z0-9]{2,100})\.([a-zA-Z0-9]{2,6})([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
            var testURL = $("#file_url").val();
            //hiding insert
            //console.log("online url value" + testURL);
            if (testURL === ""){
                $("#file_url").removeClass("is-valid");
                $("#file_url").removeClass("is-invalid");
                $("#3d_push").removeAttr("disabled", true);
                $("#linkURL").html("");
            } else {
                $("#InsertImg").addClass("d-none");
                if (pattern.test(testURL)){
                    //test successfull
                    $("#linkURL").html("<p class=\"text-success\">This is a valid link.");
                    $("#file_url").addClass("is-valid");
                    $("#file_url").removeClass("is-invalid");
                    $("#3d_push").removeAttr("disabled", true);
                } else {
                    //test failed
                    $("#linkURL").html("<p class=\"text-danger\">This is an invalid link. It must have https:// in the link.");
                    $("#file_url").addClass("is-invalid");
                    $("#3d_push").attr("disabled", true);
                }
            }
        });

        $("#friend_email").on("input", function(){
            var pGvalid = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(this.value) && this.value.length;
            var test = $("#request").val();
            var invalidaddress = new RegExp('(@test)|(@example)');
            if (pGvalid){
                if (invalidaddress.test(test)){
                    $("#valid").html("<p class=\"text-danger\">This is not a valid email address.</p>");
                    $("#request").removeClass("is-valid");
                    $("#request").addClass("is-invalid");
                } else {
                    $("#valid").html("<p class=\"text-success\">This is a valid email address.</p>");
                    $("#request").removeClass("is-invalid");
                    $("#request").addClass("is-valid");
                }
            } else {
                $("#valid").html("<p class=\"text-danger\">This is not a valid email address.</p>");
                $("#request").addClass("is-invalid");
                $("#request").removeClass("is-valid");
            }
        });

        var _URL = window.URL || window.webkitURL;
        $("[accept*='.obj, .stl'").on("change", function(){
            //console.log("file has been selected");
            var file = $(this)[0].files[0];
            if (file === null) {
                $("#filesize").html("<p></p>");
                $(this).removeClass("is-valid");
                $(this).removeClass("is-invalid");
                $("#3d_push").removeAttr("disabled", true);
            } else {
                if (file != null) {
                    var fileextension = ['stl', 'obj'];
                    if($.inArray($(this).val().split('.').pop().toLowerCase(), fileextension) == -1) {
                        $("#filesize").html("<p class='text-danger'>Only" + fileextension.join(', ') + "formats are allowed</p>");
                        $("#3d_push").attr("disabled", true);
                    } else {
                        var size = $(this)[0].files[0].size;
                        var maxsize = 30000000;
                        console.log(size + " of file");
                        if (size === null) {
                            $("#filesize").html("<p></p>");
                            $(this).removeClass("is-invalid");
                            $(this).removeClass("is-valid");
                        } else if (size <= maxsize){
                            $("#filesize").html("<p class='text-success'>3d print file size is the correct size for our website.</p>");
                            $(this).addClass("is-valid");
                            $(this).removeClass("is-invalid");
                            $("#3d_push").removeAttr("disabled", true);
                        } else {
                            $("#filesize").html("<p class='text-danger'>3d print file size is too big, please use our file link for files over 25MB.</p>");
                            $(this).addClass("is-invalid");
                            $(this).removeClass("is-valid");
                            $("#3d_push").attr("disabled", true);
                        }
                    }
                } else {
                    $("#filesize").html("");
                    $("#3d_push").removeAttr("disabled", true);
                    $(this).removeClass("is-invalid");
                    $(this).removeClass("is-valid");
                }

            }

        });

    }
}

