/*scheduling event copy and registration button*/

export default class goLive {
    constructor() {

        const eventGoLive = document.getElementById('harry');

        if (eventGoLive) {
            this.eventScheduler();
        }
    }

    eventScheduler() {
        console.log("scheduler added");


        const start = $("#event-reg-start").text();
        console.log("start is "+start);
        const end = $("#event-reg-after").text();
        console.log("end is "+end);

        const currentTime = new Date();
        console.log("Now is "+currentTime);

        if (start === '') {
            var eventStart = 'not used';
        } else {
            var eventStart = new Date(start);
            console.log("It begins " + eventStart);
        }

        if (end === ''){
            var eventEnd = 'not used';
        } else {
            var eventEnd = new Date(end);
            console.log("It ends " + eventEnd);
        }

        if (eventStart === 'not used') {
            //do not show before text at all!
        } else {
            //ok now see if end is being used?
            if(eventEnd === 'not used'){
                //do not show after text at all!
            } else {
                if (currentTime <= eventStart){
                    console.log("Before copy please!");
                    //before event registration
                    //hide during copy
                    $("#duringHoudini").addClass("d-none");
                    //hide after copy
                    $("#afterHoudini").addClass("d-none");
                    //hide registraiton btn
                    $("#linkHoudini").addClass("d-none");
                    //show before copy
                    $("#beforeHoudini").removeClass("d-none");
                } else if (currentTime >= eventEnd){
                    console.log("After copy please!");
                    //after event registration
                    //hide before copy
                    $("#beforeHoudini").addClass("d-none");
                    //hide during copy
                    $("#duringHoudini").addClass("d-none");
                    //hide registration btn
                    $("#linkHoudini").addClass("d-none");
                    //show after copy
                    $("#afterHoudini").removeClass("d-none");
                } else {
                    console.log("During copy please!");
                    //event registration is happening!
                    //hide before copy
                    $("#beforeHoudini").addClass("d-none");
                    //hide after copy
                    $("#afterHoudini").addClass("d-none");
                    //show registration btn
                    //hide reg btn
                    $("#linkHoudini").removeClass("d-none");
                    //show during copy
                    $("#duringHoudini").removeClass("d-none");
                }
            }
        }


    }
}

