/* fancy box enabling */

export default class testBoxesFancy{
    constructor(){
        const testFancyBoxing = document.getElementById("fancybox-test");

        if (testFancyBoxing){
            this.boxTestFancy();
        }
    }

    boxTestFancy(){

        const fancybox = new Fancybox([]);

        fancybox.bind("[data-fancybox]", {
            on: {
                done: (fancybox) => {
                    // here `fancybox` refers to the current instance
                },
            },
        });
        console.log("Fancybox is enabled");


    }
}

