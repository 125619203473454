/*create slider for movies page*/

export default class heavyLoad {
    constructor() {
        //pages must have an id="heavyload" for script to run
        const heavy = document.getElementById('heavyload');
        //if its found, then run function
        if (heavy) {
            this.setSpinner();
        }
    }
//hey we need to hide this class but we also need a timer just in case

    setSpinner() {

        console.log("Spinner Added");

        //get images being loaded
        let imagesLoaded = 0;
        let imagesCount = $("img").length;
        console.log("images found "+imagesCount);

        setTimeout(function(){
            $('.bookspinner').addClass(" d-none");
            console.log("timer removed");
        }, 5000);

        // $("img").each(function (idx, img) {
        //     $("<img>").on("load", imagesLoaded).attr("src", $(img).attr("src"));
        // });
        //
        // $("img").on("load", function (event) {
        //     imagesLoaded++;
        //     console.log("image loaded " + imagesLoaded);
        //     if (imagesLoaded == imagesCount) {
        //         console.log("All images loaded");
        //         $('.bookspinner').addClass(" d-none");
        //         console.log("spinner removed");
        //     } else {
        //         setTimeout(function(){
        //             $('.bookspinner').addClass(" d-none");
        //             console.log("timer removed");
        //         }, 5000);
        //     }
        // });

    }

}

